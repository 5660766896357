import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormDialogComponent } from './form-dialog.component';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { FormsModule } from '@angular/forms';

@Component({
  imports: [
    FormsModule,
    TranslateModule,
    FormDialogComponent,
    ConfirmDialogComponent,
  ],
  standalone: true,
  selector: 'app-delete-channel-dialog',
  template: `
    <app-confirm-dialog
      [useDangerButton]="true"
      [closeButtonEnabled]="inputField === 'delete'"
      closeButtonText="PROCEED"
      dismissButtonText="CANCEL"
    >
      <p class="bg-danger text-light p-3 rounded">
        {{ 'DELETE_CHANNEL_PROMPT' | translate }}
      </p>
      <p class="py-2 fw-bold">
        {{ 'ENTER_DELETE_TO_CONFIRM' | translate }}
      </p>

      <div class="form-group">
        <input
          [(ngModel)]="inputField"
          class="form-control form-control-dark"
          placeholder="delete"
        />
      </div>
    </app-confirm-dialog>
  `,
})
export class DeleteChannelDialogComponent {
  inputField?: string;
}
